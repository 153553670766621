import React from 'react'

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#333',
    // color: '#ededed',
    color: 'rgba(255,255,255,.7)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em',
    },
    '& svg': {
      fontSize: '1.5em',
    },
  },
}))
const CustomAlert = ({ severity, text }) => {
  const classes = useStyles()
  return (
    <Alert severity={severity} className={classes.root}>
      {text}
    </Alert>
  )
}
CustomAlert.defaultProps = {
  severity: 'warning',
}
export default CustomAlert
